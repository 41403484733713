import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import {
  Col, Button, Icon, Input, DatePicker, Form, Collapse, message, Modal,
  Checkbox, Divider, Tooltip, Progress, Alert,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch, faEye, faEdit, faFileExport, faPrint,
} from '@fortawesome/free-solid-svg-icons';
import fileDownload from 'js-file-download';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import Row from '../../components/Row';
import Select from '../../components/Select';
import ServerSideTable from '../../components/ServerSideTable';
import {
  fetchWaybillList, printWaybillList, exportWaybillItems, exportWaybill, saveExportTemplateToState,
  defaultTemplate,
} from './newDucks';
import { fetchOptions, fetchOptionsLike } from '../ducks';

export class WaybillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waybillList: {
        rows: [],
        total: 0,
        pages: 0,
      },
      advancedFilter: {
        address_province: null,
        address_city: null,
        tracking_number: null,
        package_id: null,
        reference: null,
        created_by: null,
        client: null,
        branch_code: null,
        status: null,
        handover_date: null,
        first_delivery_date: null,
        last_delivery_date: null,
        created_at: null,
        updated_at: null,
        order_no: null,
      },
      options: {
        created_by: [],
        client: [],
        branch_code: [],
      },
      currentPageSize: 0,
      currentFilter: [],
      currentSort: [],
      hasAdvancedFilter: false,
      isLoading: true,
      isFetchingSelect: false,
      isGeneratingReport: false,
      datepickerValueState: null,
      dateRefernceFilterState: null,
      isWaybillLabelModalVisible: false,
      isStickerQtyModalVisible: false,
      stickerQuantity: 0,
      exportModalVisibility: false,
      localExportTemplate: {},
      exportLoadingProgress: null,
      isExporting: false,
      exportAlertType: null,
      exportAlertMessage: null,
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.dateDatepickerHandler = this.dateDatepickerHandler.bind(this);
    this.applyAdvanceFilter = this.applyAdvanceFilter.bind(this);
    this.handleMassPrint = this.handleMassPrint.bind(this);
    this.handleWaybillExportItems = this.handleWaybillExportItems.bind(this);
    this.handleExportWaybill = this.handleExportWaybill.bind(this);
    this.hasFilter = this.hasFilter.bind(this);
    this.templateHandler = this.templateHandler.bind(this);

    this.tooltips = {
      tracking_number: 'The shipment\'s unique waybill number used for tracking',
      package_id: 'The unique identifier for the shipment usually filled up with order ID',
      order_no: 'The shipment\'s order number',
      status: 'The shipment\'s current status',
      remitted: '',
      status_reference: 'The shipment\'s current status reference',
      reference_1: 'The shipment\'s additional reference or serial number',
      reference_2: 'The shipment\'s additional reference or asset number',
      reference_3: 'The shipment\'s additional reference',
      reference_4: 'The shipment\'s additional reference',
      reference_5: 'The shipment\'s additional reference',
      group_id: 'The unique identifier for the group/couple of the shipment used mainly for multi-part packages',
      payment_type: 'The shipment\'s identifier for collection method.',
      total_price: 'The amount to be collected from consignee during delivery. User 0 if nothing will be collected',
      declared_value: 'The cost of goods sold',
      package_size: 'The named size of the package. E.g. Pouch, Small Box, Large Box',
      package_total_quantity: 'The sum of quantity of all items in the shipment',
      package_length: 'The total length of the shipment',
      package_width: 'The total width of the shipment',
      package_height: 'The total height of the shipment',
      package_weight: 'The total weight of the shipment',
      package_type: 'The type of order availed for the package.',
      package_description: 'The shipment\'s consolidated item descriptions.',
      package_remarks: 'The additional instructions for the shipment',
      delivery_type: 'The type of service availed for the package.',
      transport_mode: 'The method of transport used to transfer the package.',
      shipping_type: 'The availed milestone of the shipment.',
      journey_type: 'The indicator of what journey should the shipment undertake.',
      item_id: 'The shipment\'s item entry reference',
      item_description: 'The shipment\'s item entry description',
      consignee_name: 'The shipment\'s consignee',
      consignee_mobile_number: 'The shipment\'s consignee\'s mobile number',
      consignee_email_address: 'The shipment\'s consignee\'s e-mail address',
      consignee_full_address: 'The shipment\'s consignee\'s full shipping address',
      consignee_province: 'The shipment\'s consignee\'s shipping address province',
      consignee_city: 'The shipment\'s consignee\'s shipping address city',
      consignee_barangay: 'The shipment\'s consignee\'s shipping address barangay',
      consignee_building_type: 'The shipment\'s consignee\'s shipping address building type.',
      consignee_coordinate: 'The shipment\'s consignee\'s address map coordinate.',
      shipper_id: 'The shipment\'s shippers\' system designated ID',
      shipper_name: 'The shipment\'s shippers\' name',
      shipper_contact_number: 'The shipment\'s shippers\' contact number',
      shipper_address: 'The shipment\'s shippers\' full address',
      shipper_cluster: 'The shipment\'s shippers\' address cluster',
      shipper_port_code: 'The shipment\'s shippers\' assigned port code',
      shipper_barangay: 'The shipment\'s shippers\' address barangay',
      shipper_city: 'The shipment\'s shippers\' address city',
      shipper_province: 'The shipment\'s shippers\' address province',
      ageing: 'The shipment\'s current age in days since handover.',
      first_attempt_status: 'The status of shipment\'s first delivery attempt.',
      first_attempt_description: 'The reference of shipment\'s first delivery attempt.',
      first_attempt_comment: 'The remarks of shipment\'s first delivery attempt.',
      first_attempt_date: 'The date of shipment\'s first delivery attempt.',
      second_attempt_status: 'The status of shipment\'s second delivery attempt.',
      second_attempt_description: 'The reference of shipment\'s second delivery attempt.',
      second_attempt_comment: 'The remarks of shipment\'s second delivery attempt.',
      second_attempt_date: 'The date of shipment\'s second delivery attempt.',
      third_attempt_status: 'The status of shipment\'s third delivery attempt.',
      third_attempt_description: 'The reference of shipment\'s third delivery attempt.',
      third_attempt_comment: 'The remarks of shipment\'s third delivery attempt.',
      third_attempt_date: 'The date of shipment\'s third delivery attempt.',
      last_status_date: 'The status of shipment\'s last status.',
      last_status_reference: 'The reference of shipment\'s last status.',
      last_status_remarks: 'The remarks of shipment\'s last status.',
      updated_by: 'The last user who updated the package.',
      picked_date: 'The shipment\'s picked date.',
      accepted_main_date: 'The shipment\'s first acceptance to hub date.',
      transfer_date: 'The shipment\'s first transfer dispatch date.',
      accepted_branch_reference: 'The shipment\'s first accepted to branch location.',
      accepted_branch_date: 'The shipment\'s first accepted to branch date.',
      delivery_date: 'The shipment\'s delivered date.',
      last_delivery_date: 'The shipment\'s last delivery attempt date.',
      handover_date: 'The shipment\'s handover date.',
      number_of_attempts: 'The shipment\'s number of delivery attempts performed.',
      location: 'The shipment\'s current branch location.',
      target_location: 'The shipment\'s designated target branch.',
      package_cluster: 'The shipment\'s assigned cluster.',
      received_by: 'The shipment\'s delivered status recipient.',
      pickup_datetime: 'The shipment\'s booked pick up time.',
      initial_pickup_datetime: 'The shipment\'s original booked pick up time',
      pickup_start_time: 'The shipment\'s booked pick up time starting period.',
      pickup_end_time: 'The shipment\'s booked pick up time ending period.',
      created_at: 'The shipment\'s creation date.',
      updated_at: 'The shipment\'s last update.',
    };
  }

  componentDidMount() {
    const { doFetchOptions, reduxExportTemplate } = this.props;

    const selectOptions = [
      'user', 'branch_code', 'status', 'client',
    ];

    Object.keys(selectOptions).forEach((key) => {
      doFetchOptions(selectOptions[key]).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [selectOptions[key]]: selectOptions[key] === 'status'
              ? action.payload.data
              : action.payload.data.slice(0, 50),
          },
        }));
      });
    });

    this.setState({ localExportTemplate: reduxExportTemplate });
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { hasAdvancedFilter, advancedFilter } = this.state;
    const { doFetchWaybillList } = this.props;
    const advFilter = [];

    this.setState({
      isLoading: true,
      currentPageSize: state.pageSize,
      currentFilter: state.filtered,
      currentSort: state.sorted,
    });

    if (hasAdvancedFilter) {
      Object.entries(advancedFilter).forEach((item) => {
        if (item[1] !== null) {
          advFilter.push(
            { id: item[0], value: item[1] },
          );
        }
      });
    }

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: [
        ...state.filtered,
        ...advFilter,
      ],
      sorted: state.sorted,
    };

    if (hasAdvancedFilter) {
      doFetchWaybillList(params).then((action) => {
        this.setState({
          waybillList: {
            rows: action.payload.data.rows,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          },
          isLoading: false,
        });
      });
    }
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isLoading: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isLoading: false,
      }));
    });
  }

  inputHandler(type, e) {
    const { value } = e.target;
    this.setState(prevState => ({
      advancedFilter: {
        ...prevState.advancedFilter,
        [type]: value,
      },
    }));
  }

  selectHandler(type, e) {
    this.setState(prevState => ({
      advancedFilter: {
        ...prevState.advancedFilter,
        [type]: e,
      },
    }));
  }

  dateDatepickerHandler(type, e) {
    const dateReferenceSelection = {
      handover_date: null,
      first_delivery_date: null,
      last_delivery_date: null,
      created_at: null,
      updated_at: null,
    };
    const {
      datepickerValueState: datePickerSub,
      dateRefernceFilterState: dateReferenceSub,
    } = this.state;
    let datepickerValue = datePickerSub;
    let dateRefernceFilter = !dateReferenceSub ? 'handover_date' : dateReferenceSub;
    if (type === 'date_reference') {
      dateRefernceFilter = e;
    }
    if (type === 'dateRange') {
      datepickerValue = `${format(e[0], 'YYYY-MM-DD HH:mm')}|${format(e[1], 'YYYY-MM-DD HH:mm')}`;
    }
    if (datePickerSub) {
      this.setState(prevState => ({
        advancedFilter: {
          ...prevState.advancedFilter,
          ...dateReferenceSelection,
          [dateRefernceFilter !== undefined ? dateRefernceFilter : 'handover_date']: datePickerSub !== 'Invalid Date|Invalid Date' ? datePickerSub : '|',
        },
        datepickerValueState: datePickerSub,
        dateRefernceFilterState: dateRefernceFilter,
      }));
    }
    this.setState(prevState => ({
      advancedFilter: {
        ...prevState.advancedFilter,
        ...dateReferenceSelection,
        [dateRefernceFilter !== undefined ? dateRefernceFilter : 'handover_date']: datepickerValue !== 'Invalid Date|Invalid Date' ? datepickerValue : '|',
      },
      datepickerValueState: datepickerValue,
      dateRefernceFilterState: dateRefernceFilter,
    }));
  }

  applyAdvanceFilter() {
    const {
      currentPageSize, currentFilter, currentSort, advancedFilter,
    } = this.state;
    const params = {
      page: 0,
      pageSize: currentPageSize,
      filtered: currentFilter,
      sorted: currentSort,
    };
    const trackingNumberFilterCount = advancedFilter.tracking_number !== null ? advancedFilter.tracking_number.split('\n').filter(tn => tn !== '') : [];
    if (trackingNumberFilterCount.length > 50000) {
      message.error('You have reached the limit of 50,000 tracking numbers in filter');
    } else {
      this.setState({
        hasAdvancedFilter: true,
      }, () => this.onFetchData(params));
    }
  }

  handleMassPrint(type, qty = 1) {
    const { advancedFilter } = this.state;
    const { doPrintWaybillList } = this.props;

    if (advancedFilter.tracking_number !== '' && advancedFilter.tracking_number !== null) {
      this.setState({
        isGeneratingReport: true,
      });

      const params = {
        filter: [
          { id: 'tracking_number', value: advancedFilter.tracking_number },
          { id: 'type', value: type },
          { id: 'quantity', value: qty },

        ],
      };

      doPrintWaybillList(params).then((action) => {
        this.setState({
          isGeneratingReport: false,
        }, () => {
          const file = new Blob([action.payload.data], { type: 'application/pdf' });
          fileDownload(file, 'Waybill-Item-List.pdf');
        });
      }).catch(() => {
        message.error('Package doesn\'t exist!');
        this.setState({
          isGeneratingReport: false,
        });
      });
    } else {
      message.warning('Please add tracking number(s) before proceeding to print');
    }
  }

  handleWaybillExportItems() {
    const { advancedFilter } = this.state;
    const { doExportWaybillItems } = this.props;

    if (advancedFilter.tracking_number !== '' && advancedFilter.tracking_number !== null) {
      this.setState({
        isGeneratingReport: true,
      });

      const params = {
        filter: [{ id: 'tracking_number', value: advancedFilter.tracking_number }],
      };

      doExportWaybillItems(params).then((action) => {
        this.setState({
          isGeneratingReport: false,
        }, () => {
          const file = new Blob([action.payload.data]);
          fileDownload(file, 'Waybill-Item-List.csv');
        });
      }).catch(() => {
        message.error('Something went wrong, please try again later');
        this.setState({
          isGeneratingReport: false,
        });
      });
    } else {
      message.warning('Please add tracking number(s) before proceeding to export');
    }
  }

  // old waybill export

  // handleExportWaybill() {
  //   const { advancedFilter } = this.state;
  //   const { doFetchWaybillList } = this.props;
  //   const advFilter = [];

  //   this.setState({
  //     isGeneratingReport: true,
  //   });

  //   Object.entries(advancedFilter).forEach((item) => {
  //     if (item[1] !== null) {
  //       advFilter.push(
  //         { id: item[0], value: item[1] },
  //       );
  //     }
  //   });

  //   const params = {
  //     filter: advFilter,
  //     export: true,
  //   };

  //   doFetchWaybillList(params).then((action) => {
  //     this.setState({
  //       isGeneratingReport: false,
  //     }, () => {
  //       const file = new Blob([action.payload.data]);
  //       fileDownload(file, 'Item Report.csv');
  //     });
  //   }).catch(() => {
  //     message.error('Somethng went wrong, please try again later.');
  //     this.setState({
  //       isGeneratingReport: false,
  //     });
  //   });
  // }

  handleExportWaybill() {
    const { advancedFilter, waybillList } = this.state;
    const { form, doExportWaybill, doSaveExportTemplateToState } = this.props;
    const { validateFields } = form;

    if (waybillList.total > 180000) {
      message.warning('Export is only limited up to 180,000 records. Please tighten your filter or export it by batch.');
      return false;
    }

    validateFields((err, values) => {
      if (!err) {
        const selectedColumns = [];
        const columns = Object.keys(values);
        columns.forEach((column) => {
          if (values[column]) {
            selectedColumns.push(column);
          }
        });
        const parsedTrackingNumbers = advancedFilter.tracking_number ? advancedFilter.tracking_number.split('\n') : [];
        const parsedOrderNumbers = advancedFilter.order_no ? advancedFilter.order_no.split('\n') : [];
        const parsedPackageIds = advancedFilter.package_id ? advancedFilter.package_id.split('\n') : [];
        const parsedReference = advancedFilter.reference ? advancedFilter.reference.split('\n') : [];

        const payload = {
          filters: {
            ...advancedFilter,
            tracking_number: parsedTrackingNumbers,
            order_no: parsedOrderNumbers,
            package_id: parsedPackageIds,
            reference: parsedReference,
          },
          selected_columns: selectedColumns,
        };

        if (waybillList.rows.length) {
          const estimatedSize = ((selectedColumns.length * 4) * waybillList.total) / 1000;
          const serverTime = waybillList.total / 2200;
          const downloadTime = estimatedSize / 375;
          const estimatedExportTime = serverTime + downloadTime;

          let progress = 0;
          let timeStart = 0;
          const loader = setInterval(() => {
            progress += (timeStart / estimatedExportTime);
            this.setState({ exportLoadingProgress: progress > 1 ? 1 : progress });
            timeStart += 1;
          }, 1000);

          this.setState({
            isLoading: true,
            exportModalVisibility: false,
            isExporting: true,
            exportAlertType: 'info',
            exportAlertMessage: 'Exporting file. . .',
          }, () => loader);
          doExportWaybill(
            payload,
          ).then((res) => {
            clearInterval(loader);
            this.setState({
              isLoading: false,
              exportLoadingProgress: 100,
              isExporting: false,
              exportAlertType: 'success',
              exportAlertMessage: 'File successfully exported.',
            }, () => {
              doSaveExportTemplateToState(values);
              const file = new Blob([res.payload.data]);
              fileDownload(file, `Waybill-${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
            });
          }).catch(() => {
            clearInterval(loader);
            this.setState({
              isLoading: false,
              exportLoadingProgress: 0,
              exportAlertType: 'error',
              exportAlertMessage: 'Failed to export file.',
            });
          });
        } else {
          message.error('No data to export.');
        }
      }
    });

    return true;
  }

  hasFilter() {
    const { advancedFilter } = this.state;
    const filters = Object.values(advancedFilter);
    const hasFilter = filters.some(filter => (
      filter !== null
      && filter !== ''
      && filter !== undefined
      && filter !== []
      && filter !== '|'
    ));
    return hasFilter;
  }

  templateHandler(value, field) {
    this.setState(prevState => ({
      localExportTemplate: {
        ...prevState.localExportTemplate,
        [field]: value.target.checked,
      },
    }));
  }

  templateSelectAll() {
    const { localExportTemplate } = this.state;
    const fields = Object.keys(localExportTemplate);
    const tempLocalExportTemplate = { ...localExportTemplate };
    fields.forEach((field) => {
      tempLocalExportTemplate[field] = true;
    });
    this.setState({ localExportTemplate: tempLocalExportTemplate });
  }

  templateSelectDefault() {
    this.setState({ localExportTemplate: defaultTemplate });
  }

  render() {
    const {
      waybillList,
      isLoading,
      isFetchingSelect,
      options,
      isGeneratingReport,
      hasAdvancedFilter,
      isWaybillLabelModalVisible,
      isStickerQtyModalVisible,
      stickerQuantity,
      exportModalVisibility,
      localExportTemplate,
      exportLoadingProgress,
      isExporting,
      exportAlertType,
      exportAlertMessage,
    } = this.state;
    const { form, reduxExportTemplate, userSystem } = this.props;
    const { RangePicker } = DatePicker;
    const { Panel } = Collapse;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Waybill',
      },
      {
        breadcrumbName: 'List',
      },
    ];
    const dateReferenceOptions = [
      {
        label: 'Handover Date',
        value: 'handover_date',
      },
      {
        label: 'First Delivery Date',
        value: 'first_delivery_date',
      },
      {
        label: 'Last Delivery Date',
        value: 'last_delivery_date',
      },
      {
        label: 'Date Created',
        value: 'created_at',
      },
      {
        label: 'Date Updated',
        value: 'updated_at',
      },
    ];

    const inputLayout = {
      labelCol: { span: 17 },
      wrapperCol: { span: 7 },
    };

    const checkBoxStyle = {
      margin: 0,
    };
    return (
      <div className="WaybillList">
        <PageHeader title="Waybill" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Waybill Advanced Search" key="1">
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Creator">
                    <Select
                      allowClear
                      onChange={(e) => { this.selectHandler('created_by', e); }}
                      options={options.user}
                      placeholder="Select client"
                      style={{ width: '100%' }}
                      onSearch={e => this.searchOption('created_by', e)}
                      loading={isFetchingSelect}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Client">
                    <Select
                      mode="multiple"
                      onChange={(e) => { this.selectHandler('client', e); }}
                      options={options.client}
                      allowClear
                      loading={isFetchingSelect}
                      onSearch={e => this.searchOption('client', e)}
                      filterOption={false}
                      placeholder="Type to search for client"
                      showSearch
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Branch Code">
                    <Select
                      allowClear
                      onChange={(e) => { this.selectHandler('branch_code', e); }}
                      options={options.branch_code}
                      placeholder="Select branch code"
                      style={{ width: '100%' }}
                      onSearch={e => this.searchOption('branch_code', e)}
                      loading={isFetchingSelect}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Status">
                    <Select
                      mode="multiple"
                      allowClear
                      onChange={(e) => { this.selectHandler('status', e); }}
                      options={options.status}
                      placeholder="Select status"
                      style={{ width: '100%' }}
                      isSearchable="true"
                      loading={isFetchingSelect}
                      optionFilterProp="children"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Province">
                    <Input onChange={(e) => { this.inputHandler('address_province', e); }} placeholder="Enter province" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="City">
                    <Input onChange={(e) => { this.inputHandler('address_city', e); }} placeholder="Enter city" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Date Reference">
                    <Select
                      allowClear
                      onChange={(e) => { this.dateDatepickerHandler('date_reference', e); }}
                      options={dateReferenceOptions}
                      placeholder="Select date reference"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={16}>
                  <Form.Item label="Date Range">
                    <RangePicker
                      onChange={e => this.dateDatepickerHandler('dateRange', e)}
                      style={{ width: '100%' }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Tracking Number">
                    <Input.TextArea onChange={(e) => { this.inputHandler('tracking_number', e); }} placeholder="Enter tracking number(s)" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Package ID">
                    <Input.TextArea onChange={(e) => { this.inputHandler('package_id', e); }} placeholder="Enter package id(s)" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Reference">
                    <Input.TextArea onChange={(e) => { this.inputHandler('reference', e); }} placeholder="Enter reference(s)" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Order Number">
                    <Input.TextArea onChange={(e) => { this.inputHandler('order_no', e); }} placeholder="Enter order number(s)" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={this.applyAdvanceFilter}
                  disabled={!this.hasFilter()}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                  </Icon>
                      Search
                </Button>
              </Row>
            </Panel>
          </Collapse>
          <Spacer />
          {exportLoadingProgress !== null ? (
            <React.Fragment>
              <Alert
                type={exportAlertType}
                message={exportAlertMessage}
                description={(
                  <Progress
                    percent={Math.floor(exportLoadingProgress)}
                  />
              )}
                closable={!isExporting}
                showIcon
                afterClose={() => this.setState({ exportLoadingProgress: null })}
              />
              <Spacer />
            </React.Fragment>
          ) : null}

          <Modal
            title="Export Builder"
            visible={exportModalVisibility}
            okText={isLoading ? 'Exporting. . .' : 'Export'}
            onOk={() => this.handleExportWaybill()}
            onCancel={() => this.setState({ exportModalVisibility: false })}
            bodyStyle={{ paddingTop: '0px' }}
            style={{ top: 20 }}
            width="80%"
            okButtonProps={{
              disabled: isLoading,
            }}
            cancelButtonProps={{
              disabled: isLoading,
            }}
          >
            <Form>
              <Spacer />
              <Row>
                <Col span={12}>
                  <Button block type="primary" onClick={() => this.templateSelectAll()}>Select All</Button>
                </Col>
                <Col span={12}>
                  <Button block type="primary" onClick={() => this.templateSelectDefault()}>Select Default</Button>
                </Col>
              </Row>
              <Divider orientation="left"><h3>Package</h3></Divider>
              <Row>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.client}>
                            Client
                      </Tooltip>
                            )}
                  >
                    {getFieldDecorator('client', {
                      initialValue: localExportTemplate.client,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.client}
                      checked={localExportTemplate.client}
                      onChange={e => this.templateHandler(e, 'client')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.tracking_number}>
                                Tracking number
                      </Tooltip>
                            )}
                  >
                    {getFieldDecorator('tracking_number', {
                      initialValue: localExportTemplate.tracking_number,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.tracking_number}
                      checked={localExportTemplate.tracking_number}
                      onChange={e => this.templateHandler(e, 'tracking_number')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.order_no}>Order Number</Tooltip>
                        }
                  >
                    {getFieldDecorator('order_no', {
                      initialValue: localExportTemplate.order_no,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.order_no}
                      checked={localExportTemplate.order_no}
                      onChange={e => this.templateHandler(e, 'order_no')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.status}>Status</Tooltip>
                        }
                  >
                    {getFieldDecorator('status', {
                      initialValue: localExportTemplate.status,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.status}
                      checked={localExportTemplate.status}
                      onChange={e => this.templateHandler(e, 'status')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.remitted}>Remitted</Tooltip>
                        }
                  >
                    {getFieldDecorator('remitted', {
                      initialValue: localExportTemplate.remitted,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.remitted}
                      checked={localExportTemplate.remitted}
                      onChange={e => this.templateHandler(e, 'remitted')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.status_reference}>Status Reference</Tooltip>
                        }
                  >
                    {getFieldDecorator('status_reference', {
                      initialValue: localExportTemplate.status_reference,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.status_reference}
                      checked={localExportTemplate.status_reference}
                      onChange={e => this.templateHandler(e, 'status_reference')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_id}>Package ID</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_id', {
                      initialValue: localExportTemplate.package_id,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_id}
                      checked={localExportTemplate.package_id}
                      onChange={e => this.templateHandler(e, 'package_id')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.reference_1}>Reference 1</Tooltip>
                        }
                  >
                    {getFieldDecorator('reference_1', {
                      initialValue: localExportTemplate.reference_1,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.reference_1}
                      checked={localExportTemplate.reference_1}
                      onChange={e => this.templateHandler(e, 'reference_1')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.reference_2}>Reference 2</Tooltip>
                        }
                  >
                    {getFieldDecorator('reference_2', {
                      initialValue: localExportTemplate.reference_2,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.reference_2}
                      checked={localExportTemplate.reference_2}
                      onChange={e => this.templateHandler(e, 'reference_2')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.reference_3}>Reference 3</Tooltip>
                        }
                  >
                    {getFieldDecorator('reference_3', {
                      initialValue: localExportTemplate.reference_3,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.reference_3}
                      checked={localExportTemplate.reference_3}
                      onChange={e => this.templateHandler(e, 'reference_3')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.reference_4}>Reference 4</Tooltip>
                        }
                  >
                    {getFieldDecorator('reference_4', {
                      initialValue: localExportTemplate.reference_4,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.reference_4}
                      checked={localExportTemplate.reference_4}
                      onChange={e => this.templateHandler(e, 'reference_4')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.reference_5}>Reference 5</Tooltip>
                        }
                  >
                    {getFieldDecorator('reference_5', {
                      initialValue: localExportTemplate.reference_5,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.reference_5}
                      checked={localExportTemplate.reference_5}
                      onChange={e => this.templateHandler(e, 'reference_5')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.group_id}>Group ID</Tooltip>
                        }
                  >
                    {getFieldDecorator('group_id', {
                      initialValue: localExportTemplate.group_id,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.group_id}
                      checked={localExportTemplate.group_id}
                      onChange={e => this.templateHandler(e, 'group_id')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.item_id}>Item ID</Tooltip>
                        }
                  >
                    {getFieldDecorator('item_id', {
                      initialValue: localExportTemplate.item_id,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.item_id}
                      checked={localExportTemplate.item_id}
                      onChange={e => this.templateHandler(e, 'item_id')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.item_description}>Item Description</Tooltip>
                        }
                  >
                    {getFieldDecorator('item_description', {
                      initialValue: localExportTemplate.item_description,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.item_description}
                      checked={localExportTemplate.item_description}
                      onChange={e => this.templateHandler(e, 'item_description')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.payment_type}>Payment Type</Tooltip>
                        }
                  >
                    {getFieldDecorator('payment_type', {
                      initialValue: localExportTemplate.payment_type,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.payment_type}
                      checked={localExportTemplate.payment_type}
                      onChange={e => this.templateHandler(e, 'payment_type')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.total_price}>Total Price</Tooltip>
                        }
                  >
                    {getFieldDecorator('total_price', {
                      initialValue: localExportTemplate.total_price,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.total_price}
                      checked={localExportTemplate.total_price}
                      onChange={e => this.templateHandler(e, 'total_price')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.declared_value}>Declared Value</Tooltip>
                        }
                  >
                    {getFieldDecorator('declared_value', {
                      initialValue: localExportTemplate.declared_value,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.declared_value}
                      checked={localExportTemplate.declared_value}
                      onChange={e => this.templateHandler(e, 'declared_value')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_size}>Size</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_size', {
                      initialValue: localExportTemplate.package_size,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_size}
                      checked={localExportTemplate.package_size}
                      onChange={e => this.templateHandler(e, 'package_size')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.package_total_quantity}>
                            Total Quantity
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('package_total_quantity', {
                      initialValue: localExportTemplate.package_total_quantity,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_total_quantity}
                      checked={localExportTemplate.package_total_quantity}
                      onChange={e => this.templateHandler(e, 'package_total_quantity')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_length}>Length</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_length', {
                      initialValue: localExportTemplate.package_length,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_length}
                      checked={localExportTemplate.package_length}
                      onChange={e => this.templateHandler(e, 'package_length')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_width}>Width</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_width', {
                      initialValue: localExportTemplate.package_width,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_width}
                      checked={localExportTemplate.package_width}
                      onChange={e => this.templateHandler(e, 'package_width')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_height}>Height</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_height', {
                      initialValue: localExportTemplate.package_height,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_height}
                      checked={localExportTemplate.package_height}
                      onChange={e => this.templateHandler(e, 'package_height')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_weight}>Weight</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_weight', {
                      initialValue: localExportTemplate.package_weight,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_weight}
                      checked={localExportTemplate.package_weight}
                      onChange={e => this.templateHandler(e, 'package_weight')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_type}>Package Type</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_type', {
                      initialValue: localExportTemplate.package_type,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_type}
                      checked={localExportTemplate.package_type}
                      onChange={e => this.templateHandler(e, 'package_type')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.delivery_type}>Delivery Type</Tooltip>
                        }
                  >
                    {getFieldDecorator('delivery_type', {
                      initialValue: localExportTemplate.delivery_type,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.delivery_type}
                      checked={localExportTemplate.delivery_type}
                      onChange={e => this.templateHandler(e, 'delivery_type')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_remarks}>Remarks</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_remarks', {
                      initialValue: localExportTemplate.package_remarks,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_remarks}
                      checked={localExportTemplate.package_remarks}
                      onChange={e => this.templateHandler(e, 'package_remarks')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.ageing}>Ageing</Tooltip>
                        }
                  >
                    {getFieldDecorator('ageing', {
                      initialValue: localExportTemplate.ageing,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.ageing}
                      checked={localExportTemplate.ageing}
                      onChange={e => this.templateHandler(e, 'ageing')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.transport_mode}>Transport Mode</Tooltip>
                        }
                  >
                    {getFieldDecorator('transport_mode', {
                      initialValue: localExportTemplate.transport_mode,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.transport_mode}
                      checked={localExportTemplate.transport_mode}
                      onChange={e => this.templateHandler(e, 'transport_mode')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.shipping_type}>Shipping Type</Tooltip>
                        }
                  >
                    {getFieldDecorator('shipping_type', {
                      initialValue: localExportTemplate.shipping_type,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipping_type}
                      checked={localExportTemplate.shipping_type}
                      onChange={e => this.templateHandler(e, 'shipping_type')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.journey_type}>Journey Type</Tooltip>
                        }
                  >
                    {getFieldDecorator('journey_type', {
                      initialValue: localExportTemplate.journey_type,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.journey_type}
                      checked={localExportTemplate.journey_type}
                      onChange={e => this.templateHandler(e, 'journey_type')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.first_attempt_status}>
                          First Attempt Status
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('first_attempt_status', {
                      initialValue: localExportTemplate.first_attempt_status,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.first_attempt_status}
                      checked={localExportTemplate.first_attempt_status}
                      onChange={e => this.templateHandler(e, 'first_attempt_status')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.first_attempt_description}>
                          First Attempt Description
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('first_attempt_description', {
                      initialValue: localExportTemplate.first_attempt_description,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.first_attempt_description}
                      checked={localExportTemplate.first_attempt_description}
                      onChange={e => this.templateHandler(e, 'first_attempt_description')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.first_attempt_comment}>
                          First Attempt Comment
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('first_attempt_comment', {
                      initialValue: localExportTemplate.first_attempt_comment,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.first_attempt_comment}
                      checked={localExportTemplate.first_attempt_comment}
                      onChange={e => this.templateHandler(e, 'first_attempt_comment')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.first_attempt_date}>First Attempt Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('first_attempt_date', {
                      initialValue: localExportTemplate.first_attempt_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.first_attempt_date}
                      checked={localExportTemplate.first_attempt_date}
                      onChange={e => this.templateHandler(e, 'first_attempt_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.second_attempt_status}>
                          Second Attempt Status
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('second_attempt_status', {
                      initialValue: localExportTemplate.second_attempt_status,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.second_attempt_status}
                      checked={localExportTemplate.second_attempt_status}
                      onChange={e => this.templateHandler(e, 'second_attempt_status')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.second_attempt_description}>
                          Second Attempt Description
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('second_attempt_description', {
                      initialValue: localExportTemplate.second_attempt_description,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.second_attempt_description}
                      checked={localExportTemplate.second_attempt_description}
                      onChange={e => this.templateHandler(e, 'second_attempt_description')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.second_attempt_comment}>
                          Second Attempt Comment
                      </Tooltip>
                      )}
                  >
                    {getFieldDecorator('second_attempt_comment', {
                      initialValue: localExportTemplate.second_attempt_comment,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.second_attempt_comment}
                      checked={localExportTemplate.second_attempt_comment}
                      onChange={e => this.templateHandler(e, 'second_attempt_comment')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.second_attempt_date}>
                          Second Attempt Date
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('second_attempt_date', {
                      initialValue: localExportTemplate.second_attempt_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.second_attempt_date}
                      checked={localExportTemplate.second_attempt_date}
                      onChange={e => this.templateHandler(e, 'second_attempt_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.third_attempt_status}>
                          Third Attempt Status
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('third_attempt_status', {
                      initialValue: localExportTemplate.third_attempt_status,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.third_attempt_status}
                      checked={localExportTemplate.third_attempt_status}
                      onChange={e => this.templateHandler(e, 'third_attempt_status')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.third_attempt_description}>
                          Third Attempt Description
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('third_attempt_description', {
                      initialValue: localExportTemplate.third_attempt_description,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.third_attempt_description}
                      checked={localExportTemplate.third_attempt_description}
                      onChange={e => this.templateHandler(e, 'third_attempt_description')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.third_attempt_comment}>
                          Third Attempt Comment
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('third_attempt_comment', {
                      initialValue: localExportTemplate.third_attempt_comment,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.third_attempt_comment}
                      checked={localExportTemplate.third_attempt_comment}
                      onChange={e => this.templateHandler(e, 'third_attempt_comment')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.third_attempt_date}>Third Attempt Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('third_attempt_date', {
                      initialValue: localExportTemplate.third_attempt_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.third_attempt_date}
                      checked={localExportTemplate.third_attempt_date}
                      onChange={e => this.templateHandler(e, 'third_attempt_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.last_status_date}>Last Status Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('last_status_date', {
                      initialValue: localExportTemplate.last_status_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.last_status_date}
                      checked={localExportTemplate.last_status_date}
                      onChange={e => this.templateHandler(e, 'last_status_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.last_status_reference}>
                          Last Status Reference
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('last_status_reference', {
                      initialValue: localExportTemplate.last_status_reference,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.last_status_reference}
                      checked={localExportTemplate.last_status_reference}
                      onChange={e => this.templateHandler(e, 'last_status_reference')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.last_status_remarks}>
                          Last Status Remarks
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('last_status_remarks', {
                      initialValue: localExportTemplate.last_status_remarks,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.last_status_remarks}
                      checked={localExportTemplate.last_status_remarks}
                      onChange={e => this.templateHandler(e, 'last_status_remarks')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.updated_by}>Updated By</Tooltip>
                        }
                  >
                    {getFieldDecorator('updated_by', {
                      initialValue: localExportTemplate.updated_by,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.updated_by}
                      checked={localExportTemplate.updated_by}
                      onChange={e => this.templateHandler(e, 'updated_by')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.picked_date}>Picked Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('picked_date', {
                      initialValue: localExportTemplate.picked_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.picked_date}
                      checked={localExportTemplate.picked_date}
                      onChange={e => this.templateHandler(e, 'picked_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.accepted_main_date}>
                          Accepted To Main Date
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('accepted_main_date', {
                      initialValue: localExportTemplate.accepted_main_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.accepted_main_date}
                      checked={localExportTemplate.accepted_main_date}
                      onChange={e => this.templateHandler(e, 'accepted_main_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.transfer_date}>Transfer Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('transfer_date', {
                      initialValue: localExportTemplate.transfer_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.transfer_date}
                      checked={localExportTemplate.transfer_date}
                      onChange={e => this.templateHandler(e, 'transfer_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.accepted_branch_reference}>
                          Accepted To Branch Reference
                      </Tooltip>
                      )}
                  >
                    {getFieldDecorator('accepted_branch_reference', {
                      initialValue: localExportTemplate.accepted_branch_reference,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.accepted_branch_reference}
                      checked={localExportTemplate.accepted_branch_reference}
                      onChange={e => this.templateHandler(e, 'accepted_branch_reference')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.accepted_branch_date}>
                          Accepted To Branch Date
                      </Tooltip>
  )}
                  >
                    {getFieldDecorator('accepted_branch_date', {
                      initialValue: localExportTemplate.accepted_branch_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.accepted_branch_date}
                      checked={localExportTemplate.accepted_branch_date}
                      onChange={e => this.templateHandler(e, 'accepted_branch_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.delivery_date}>Delivery Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('delivery_date', {
                      initialValue: localExportTemplate.delivery_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.delivery_date}
                      checked={localExportTemplate.delivery_date}
                      onChange={e => this.templateHandler(e, 'delivery_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.last_delivery_date}>Last Delivery Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('last_delivery_date', {
                      initialValue: localExportTemplate.last_delivery_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.last_delivery_date}
                      checked={localExportTemplate.last_delivery_date}
                      onChange={e => this.templateHandler(e, 'last_delivery_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.handover_date}>Handover Date</Tooltip>
                        }
                  >
                    {getFieldDecorator('handover_date', {
                      initialValue: localExportTemplate.handover_date,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.handover_date}
                      checked={localExportTemplate.handover_date}
                      onChange={e => this.templateHandler(e, 'handover_date')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.location}>Location</Tooltip>
                        }
                  >
                    {getFieldDecorator('location', {
                      initialValue: localExportTemplate.location,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.location}
                      checked={localExportTemplate.location}
                      onChange={e => this.templateHandler(e, 'location')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.target_location}>Target Location</Tooltip>
                        }
                  >
                    {getFieldDecorator('target_location', {
                      initialValue: localExportTemplate.target_location,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.target_location}
                      checked={localExportTemplate.target_location}
                      onChange={e => this.templateHandler(e, 'target_location')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.package_cluster}>Cluster</Tooltip>
                        }
                  >
                    {getFieldDecorator('package_cluster', {
                      initialValue: localExportTemplate.package_cluster,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.package_cluster}
                      checked={localExportTemplate.package_cluster}
                      onChange={e => this.templateHandler(e, 'package_cluster')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.received_by}>Received By</Tooltip>
                        }
                  >
                    {getFieldDecorator('received_by', {
                      initialValue: localExportTemplate.received_by,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.received_by}
                      checked={localExportTemplate.received_by}
                      onChange={e => this.templateHandler(e, 'received_by')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.number_of_attempts}>Number of Attempts</Tooltip>
                        }
                  >
                    {getFieldDecorator('number_of_attempts', {
                      initialValue: localExportTemplate.number_of_attempts,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.number_of_attempts}
                      checked={localExportTemplate.number_of_attempts}
                      onChange={e => this.templateHandler(e, 'number_of_attempts')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.pickup_datetime}>Pickup Datetime</Tooltip>
                        }
                  >
                    {getFieldDecorator('pickup_datetime', {
                      initialValue: localExportTemplate.pickup_datetime,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.pickup_datetime}
                      checked={localExportTemplate.pickup_datetime}
                      onChange={e => this.templateHandler(e, 'pickup_datetime')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.initial_pickup_datetime}>Initial Pickup Datetime</Tooltip>
                        }
                  >
                    {getFieldDecorator('initial_pickup_datetime', {
                      initialValue: localExportTemplate.initial_pickup_datetime,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.initial_pickup_datetime}
                      checked={localExportTemplate.initial_pickup_datetime}
                      onChange={e => this.templateHandler(e, 'initial_pickup_datetime')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.pickup_start_time}>Pickup Time Start</Tooltip>
                        }
                  >
                    {getFieldDecorator('pickup_start_time', {
                      initialValue: localExportTemplate.pickup_start_time,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.pickup_start_time}
                      checked={localExportTemplate.pickup_start_time}
                      onChange={e => this.templateHandler(e, 'pickup_start_time')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.pickup_end_time}>Pickup End Time</Tooltip>
                        }
                  >
                    {getFieldDecorator('pickup_end_time', {
                      initialValue: localExportTemplate.pickup_end_time,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.pickup_end_time}
                      checked={localExportTemplate.pickup_end_time}
                      onChange={e => this.templateHandler(e, 'pickup_end_time')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.created_at}>Created At</Tooltip>
                        }
                  >
                    {getFieldDecorator('created_at', {
                      initialValue: localExportTemplate.created_at,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.created_at}
                      checked={localExportTemplate.created_at}
                      onChange={e => this.templateHandler(e, 'created_at')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.updated_at}>Updated At</Tooltip>
                        }
                  >
                    {getFieldDecorator('updated_at', {
                      initialValue: localExportTemplate.updated_at,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.updated_at}
                      checked={localExportTemplate.updated_at}
                      onChange={e => this.templateHandler(e, 'updated_at')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.delivery_min_time}> Delivery Minimum Time</Tooltip>
                        }
                  >
                    {getFieldDecorator('delivery_min_time', {
                      initialValue: localExportTemplate.delivery_min_time,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.delivery_min_time}
                      checked={localExportTemplate.delivery_min_time}
                      onChange={e => this.templateHandler(e, 'delivery_min_time')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.delivery_min_time}> Delivery Maximum Time</Tooltip>
                        }
                  >
                    {getFieldDecorator('delivery_max_time', {
                      initialValue: localExportTemplate.delivery_max_time,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.delivery_max_time}
                      checked={localExportTemplate.delivery_max_time}
                      onChange={e => this.templateHandler(e, 'delivery_max_time')}
                    />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.sellers_n1operating_schedule}> Sellers N1 Operating Schedule</Tooltip>
                        }
                  >
                    {getFieldDecorator('sellers_n1operating_schedule', {
                      initialValue: localExportTemplate.sellers_n1operating_schedule,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.sellers_n1operating_schedule}
                      checked={localExportTemplate.sellers_n1operating_schedule}
                      onChange={e => this.templateHandler(e, 'sellers_n1operating_schedule')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.sellers_n1opening_hours}> Sellers N1 Opening Hours</Tooltip>
                        }
                  >
                    {getFieldDecorator('sellers_n1opening_hours', {
                      initialValue: localExportTemplate.sellers_n1opening_hours,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.sellers_n1opening_hours}
                      checked={localExportTemplate.sellers_n1opening_hours}
                      onChange={e => this.templateHandler(e, 'sellers_n1opening_hours')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.sellers_n1closing_hours}> Sellers N1 Closing Hours</Tooltip>
                        }
                  >
                    {getFieldDecorator('sellers_n1closing_hours', {
                      initialValue: localExportTemplate.sellers_n1closing_hours,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.sellers_n1closing_hours}
                      checked={localExportTemplate.sellers_n1closing_hours}
                      onChange={e => this.templateHandler(e, 'sellers_n1closing_hours')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.sellers_n2operating_schedule}> Sellers N2 Opearing Schedule</Tooltip>
                        }
                  >
                    {getFieldDecorator('sellers_n2operating_schedule', {
                      initialValue: localExportTemplate.sellers_n2operating_schedule,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.sellers_n2operating_schedule}
                      checked={localExportTemplate.sellers_n2operating_schedule}
                      onChange={e => this.templateHandler(e, 'sellers_n2operating_schedule')}
                    />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.sellers_n2opening_schedule}> Sellers N2 Opening Schedule</Tooltip>
                        }
                  >
                    {getFieldDecorator('sellers_n2opening_schedule', {
                      initialValue: localExportTemplate.sellers_n2opening_schedule,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.sellers_n2opening_schedule}
                      checked={localExportTemplate.sellers_n2opening_schedule}
                      onChange={e => this.templateHandler(e, 'sellers_n2opening_schedule')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.	sellers_n2closing_schedule}> Sellers N2 Closing Schedule</Tooltip>
                        }
                  >
                    {getFieldDecorator('sellers_n2closing_schedule', {
                      initialValue: localExportTemplate.sellers_n2closing_schedule,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.sellers_n2closing_schedule}
                      checked={localExportTemplate.sellers_n2closing_schedule}
                      onChange={e => this.templateHandler(e, 'sellers_n2closing_schedule')}
                    />)}
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left"><h3>Consignee</h3></Divider>
              <Row>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.consignee_name}>Name</Tooltip>
                        }
                  >
                    {getFieldDecorator('consignee_name', {
                      initialValue: localExportTemplate.consignee_name,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_name}
                      checked={localExportTemplate.consignee_name}
                      onChange={e => this.templateHandler(e, 'consignee_name')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.consignee_mobile_number}>
                            Mobile Number
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('consignee_mobile_number', {
                      initialValue: localExportTemplate.consignee_mobile_number,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_mobile_number}
                      checked={localExportTemplate.consignee_mobile_number}
                      onChange={e => this.templateHandler(e, 'consignee_mobile_number')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.consignee_email_address}>
                            Email Address
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('consignee_email_address', {
                      initialValue: localExportTemplate.consignee_email_address,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_email_address}
                      checked={localExportTemplate.consignee_email_address}
                      onChange={e => this.templateHandler(e, 'consignee_email_address')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.consignee_full_address}>
                            Full Address
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('consignee_full_address', {
                      initialValue: localExportTemplate.consignee_full_address,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_full_address}
                      checked={localExportTemplate.consignee_full_address}
                      onChange={e => this.templateHandler(e, 'consignee_full_address')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.consignee_province}>Province</Tooltip>
                        }
                  >
                    {getFieldDecorator('consignee_province', {
                      initialValue: localExportTemplate.consignee_province,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_province}
                      checked={localExportTemplate.consignee_province}
                      onChange={e => this.templateHandler(e, 'consignee_province')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.consignee_city}>City</Tooltip>
                        }
                  >
                    {getFieldDecorator('consignee_city', {
                      initialValue: localExportTemplate.consignee_city,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_city}
                      checked={localExportTemplate.consignee_city}
                      onChange={e => this.templateHandler(e, 'consignee_city')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.consignee_barangay}>Barangay</Tooltip>
                        }
                  >
                    {getFieldDecorator('consignee_barangay', {
                      initialValue: localExportTemplate.consignee_barangay,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_barangay}
                      checked={localExportTemplate.consignee_barangay}
                      onChange={e => this.templateHandler(e, 'consignee_barangay')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.consignee_building_type}>
                            Building Type
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('consignee_building_type', {
                      initialValue: localExportTemplate.consignee_building_type,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_building_type}
                      checked={localExportTemplate.consignee_building_type}
                      onChange={e => this.templateHandler(e, 'consignee_building_type')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.consignee_coordinate}>
                                Coordinate
                      </Tooltip>
                            )}
                  >
                    {getFieldDecorator('consignee_coordinate', {
                      initialValue: localExportTemplate.consignee_coordinate,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.consignee_coordinate}
                      checked={localExportTemplate.consignee_coordinate}
                      onChange={e => this.templateHandler(e, 'consignee_coordinate')}
                    />)}
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left"><h3>Shipper</h3></Divider>
              <Row>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.shipper_id}>ID</Tooltip>
                        }
                  >
                    {getFieldDecorator('shipper_id', {
                      initialValue: localExportTemplate.shipper_id,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_id}
                      checked={localExportTemplate.shipper_id}
                      onChange={e => this.templateHandler(e, 'shipper_id')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.shipper_name}>Name</Tooltip>
                        }
                  >
                    {getFieldDecorator('shipper_name', {
                      initialValue: localExportTemplate.shipper_name,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_name}
                      checked={localExportTemplate.shipper_name}
                      onChange={e => this.templateHandler(e, 'shipper_name')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.shipper_contact_number}>
                            Mobile Number
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('shipper_contact_number', {
                      initialValue: localExportTemplate.shipper_contact_number,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_contact_number}
                      checked={localExportTemplate.shipper_contact_number}
                      onChange={e => this.templateHandler(e, 'shipper_contact_number')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.shipper_address}>
                            Address
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('shipper_address', {
                      initialValue: localExportTemplate.shipper_address,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_address}
                      checked={localExportTemplate.shipper_address}
                      onChange={e => this.templateHandler(e, 'shipper_address')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.shipper_port_code}>
                            Port Code
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('shipper_port_code', {
                      initialValue: localExportTemplate.shipper_port_code,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_port_code}
                      checked={localExportTemplate.shipper_port_code}
                      onChange={e => this.templateHandler(e, 'shipper_port_code')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.shipper_cluster}>
                            Cluster
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('shipper_cluster', {
                      initialValue: localExportTemplate.shipper_cluster,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_cluster}
                      checked={localExportTemplate.shipper_cluster}
                      onChange={e => this.templateHandler(e, 'shipper_cluster')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={(
                      <Tooltip title={this.tooltips.shipper_barangay}>
                            Barangay
                      </Tooltip>
                        )}
                  >
                    {getFieldDecorator('shipper_barangay', {
                      initialValue: localExportTemplate.shipper_barangay,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_barangay}
                      checked={localExportTemplate.shipper_barangay}
                      onChange={e => this.templateHandler(e, 'shipper_barangay')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.shipper_city}>City</Tooltip>
                        }
                  >
                    {getFieldDecorator('shipper_city', {
                      initialValue: localExportTemplate.shipper_city,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_city}
                      checked={localExportTemplate.shipper_city}
                      onChange={e => this.templateHandler(e, 'shipper_city')}
                    />)}
                  </Form.Item>
                </Col>
                <Col sm={8} lg={6}>
                  <Form.Item
                    style={checkBoxStyle}
                    {...inputLayout}
                    labelAlign="right"
                    label={
                      <Tooltip title={this.tooltips.shipper_province}>Province</Tooltip>
                        }
                  >
                    {getFieldDecorator('shipper_province', {
                      initialValue: localExportTemplate.shipper_province,
                    })(<Checkbox
                      defaultChecked={reduxExportTemplate.shipper_province}
                      checked={localExportTemplate.shipper_province}
                      onChange={e => this.templateHandler(e, 'shipper_province')}
                    />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Card
            title={`Total: ${waybillList.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            extra={(
              <React.Fragment>
                <Row>
                  <Col xs={24} sm={24} lg={5} style={{ marginBottom: 5 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => this.setState({ exportModalVisibility: true })}
                      disabled={!waybillList.rows.length || !this.hasFilter() || isGeneratingReport}
                      block
                    >
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faFileExport} fixedWidth />
                      </Icon>
                              Export
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} lg={10} style={{ marginBottom: 5 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={this.handleWaybillExportItems}
                      disabled={isGeneratingReport}
                      block
                    >
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faFileExport} fixedWidth />
                      </Icon>
                      Export Waybill Items
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} lg={9} style={{ marginBottom: 5 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => this.setState({
                        isWaybillLabelModalVisible: true,
                      })}
                      disabled={isGeneratingReport}
                      block
                    >
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPrint} fixedWidth />
                      </Icon>
                          Mass Waybill Print
                    </Button>
                    <Modal
                      title="Select Waybill Label"
                      visible={isWaybillLabelModalVisible}
                      footer={null}
                      onCancel={() => this.setState({
                        isWaybillLabelModalVisible: false,
                      })}
                    >
                      <Row>
                        <Col xs={12}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => this.handleMassPrint('standard')}
                            disabled={isGeneratingReport}
                            block
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPrint} fixedWidth />
                            </Icon>
                                Standard XDE Label
                          </Button>
                        </Col>
                        <Col xs={12}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => this.handleMassPrint('a6-sticker')}
                            disabled={isGeneratingReport}
                            block
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPrint} fixedWidth />
                            </Icon>
                                A6 Sticker Label
                          </Button>
                        </Col>
                      </Row><br></br>
                      <Row>
                      <Col xs={12}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => this.setState({isStickerQtyModalVisible : true, isWaybillLabelModalVisible:false})}
                            disabled={isGeneratingReport}
                            block
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPrint} fixedWidth />
                            </Icon>
                                Waybill Sticker
                          </Button>
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Waybill Sticker Quantity"
                      visible={isStickerQtyModalVisible}
                      footer={null}
                      onCancel={() => this.setState({
                        isStickerQtyModalVisible: false,
                      })}
                    >
                      <Row>
       
                          <Input
                           type='number'
                           value={stickerQuantity}
                           onChange={(e)=>{
                            const value = e.target.value;
                            if (value === "" || (Number(value) >= 0 && /^[0-9]*$/.test(value))) {
                              this.setState({stickerQuantity: value});
                            }

                           }}   
                           ></Input>
                      </Row><br></br>
                      <Row>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => this.handleMassPrint('waybill-sticker',stickerQuantity)}
                            disabled={isGeneratingReport}
                            block
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPrint} fixedWidth />
                            </Icon>
                                Print
                          </Button>
                      </Row>
                    </Modal>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          >
            <ServerSideTable
              data={waybillList.rows}
              pages={waybillList.pages}
              columns={[
                {
                  Header: 'Client',
                  filterable: false,
                  accessor: 'client_name',
                },
                {
                  Header: 'Created Date',
                  filterable: false,
                  accessor: 'created_at',
                },
                {
                  Header: 'Handover Date',
                  filterable: false,
                  accessor: 'handover_date',
                },
                {
                  Header: 'Waybill Number',
                  filterable: false,
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Consignee Name',
                  filterable: false,
                  accessor: 'consignee_name',
                },
                {
                  Header: 'City',
                  filterable: false,
                  accessor: 'address_city',
                },
                {
                  Header: 'Status',
                  filterable: false,
                  accessor: 'status',
                },
                {
                  Header: 'Reference',
                  filterable: false,
                  accessor: 'ref_code',
                },
                {
                  Header: 'Options',
                  filterable: false,
                  width: 200,
                  Cell: data => (
                    <React.Fragment>
                      {
                        <Row>
                          <Col span={12}>
                            <NavLink to={`/waybill/${data.row.tracking_number}`}>
                              <Button type="link">
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEye} fixedWidth />
                                </Icon>
                                      View
                              </Button>
                            </NavLink>
                          </Col>
                          <Col span={12}>
                            {
                              userSystem.role_id === 1 ? (
                                <NavLink to={`/waybill/edit/${data.row.tracking_number}`}>
                                  <Button type="link">
                                    <Icon viewBox="0 0 1024 1024">
                                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                                    </Icon>
                                          Edit
                                  </Button>
                                </NavLink>
                              ) : (
                                <NavLink to={`/waybill/edit/${data.row.tracking_number}`}>
                                  <Button type="link" disabled>
                                    <Icon viewBox="0 0 1024 1024">
                                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                                    </Icon>
                                          Edit
                                  </Button>
                                </NavLink>
                              )
                            }
                          </Col>
                        </Row>
                        }
                    </React.Fragment>
                  ),
                },
              ]}
              onFetchData={this.onFetchData}
              loading={!hasAdvancedFilter ? true : isLoading}
              loadingText={!hasAdvancedFilter ? 'Use the filters to search for Waybill' : 'Fetching waybill list . . .'}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

WaybillList.propTypes = {
  doFetchWaybillList: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doPrintWaybillList: PropTypes.func.isRequired,
  doExportWaybillItems: PropTypes.func.isRequired,
  doExportWaybill: PropTypes.func.isRequired,
  doSaveExportTemplateToState: PropTypes.func.isRequired,
  reduxExportTemplate: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  userSystem: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doFetchWaybillList: fetchWaybillList,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doPrintWaybillList: printWaybillList,
  doExportWaybillItems: exportWaybillItems,
  doExportWaybill: exportWaybill,
  doSaveExportTemplateToState: saveExportTemplateToState,
};

const mapStateToProps = state => ({
  reduxExportTemplate: state.waybillExport.exportTemplate,
  userSystem: state.auth.user,
});

const WrappedWaybillList = Form.create({ name: 'WaybillList' })(WaybillList);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedWaybillList);
